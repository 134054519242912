// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Pricing.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Pricing.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

export default function Pricing({
  data
}) {
  return <section className="py-20 bg-gray-900 text-gray-100 m:py-12 lg:py-24">
      <div className="container px-4 mx-auto ">
        <div className="max-w-2xl mx-auto mb-16 text-center">
          <span className="font-bold tracking-wider uppercase text-violet-400">
            Pricing
          </span>
          <h2 className="text-4xl font-bold lg:text-5xl">{data.title}</h2>
        </div>
        <div className="flex flex-wrap items-stretch max-w-5xl mx-auto">
          {data.plans.map(plan => <div key={plan.id} className="w-full p-4 mb-8  sm:mx-40 lg:mx-0 lg:w-1/3 lg:mb-0">
              <div className={`flex flex-col p-6 space-y-6 rounded shadow sm:p-8 min-h-[475px] min-w-[300px] ${plan.isRecommended ? "bg-violet-600" : "bg-gray-800"}`}>
                <div className="space-y-2">
                  <h4 className="text-3xl font-bold mb-6">{plan.name}</h4>
                  <span className="text-6xl font-bold ">
                    {plan.price}
                    <span className={`ml-1 text-sm tracking-wid ${plan.isRecommended ? "text-gray-900" : "text-violet-500"}`}>
                      {plan.pricePeriod.toLowerCase()}
                    </span>
                  </span>
                </div>
                <p className={`mt-3 leading-relaxed text-lg font-bold ${plan.isRecommended ? "text-gray-900" : "text-gray-400"}`}>
                  {plan.description}
                </p>
                <ul className={`flex-1 mb-6 ${plan.isRecommended ? "text-gray-900 font-semibold" : "text-gray-400"}`}>
                  {plan.product_features.data.map(feature => <li key={feature.id} className="flex mb-2 space-x-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`flex-shrink-0 w-6 h-6 ${plan.isRecommended ? "text-gray-900" : "text-gray-400"}`}>
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                      </svg>
                      <span>{feature.attributes.name}</span>
                    </li>)}
                </ul>
                <button type="button" className={`inline-block px-5 py-3 font-semibold tracking-wider text-center rounded   ${plan.isRecommended ? "bg-gray-900 text-violet-400" : "bg-violet-400 text-gray-900"}`}>
                  Get Started
                </button>
              </div>
            </div>)}
        </div>
      </div>
    </section>;
}
_c = Pricing;
var _c;
$RefreshReg$(_c, "Pricing");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;