// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Hero.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Hero.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import HighlightedText from "./HighlightedText";
import { getStrapiMedia } from "~/utils/api-helpers";
import { renderButtonStyle } from "~/utils/render-button-style";
export default function Hero({
  data
}) {
  const imgUrl = getStrapiMedia(data.picture.data.attributes.url);
  return <section className="bg-gray-900 text-gray-100">
      <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
        <div className="flex flex-col justify-center p-6 text-center rounded-lg lg:max-w-md xl:max-w-lg lg:text-left">
          <HighlightedText text={data.title} tag="h1" className="text-5xl font-bold leading-none sm:text-6xl mb-8" color="text-violet-400" />

          <HighlightedText text={data.description} tag="p" className="tmt-6 mb-8 text-lg sm:mb-12" color="text-violet-400" />
          <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
            {data.buttons.map((button, index) => <Link key={index} to={button.url} target={button.newTab ? "_blank" : "_self"} className={renderButtonStyle(button.type)}>
                {button.text}
              </Link>)}
          </div>
        </div>
        <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
          <img src={imgUrl || ""} alt={data.picture.data.attributes.alternativeText || "none provided"} className="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128 " />
        </div>
      </div>
    </section>;
}
_c = Hero;
var _c;
$RefreshReg$(_c, "Hero");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;