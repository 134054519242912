// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

import { useLoaderData } from "@remix-run/react";
import { fetchStrapiData } from "~/api/fetch-strapi-data.server";
import { sectionRenderer } from "~/utils/section-renderer";
export const meta = () => {
  return [{
    title: "New Remix App"
  }];
};
export async function loader() {
  const path = `/pages`;
  const slug = "home";
  const urlParamsObject = {
    filters: {
      slug
    }
  };
  const response = await fetchStrapiData(path, urlParamsObject);
  return response;
}
export default function RootRoute() {
  _s();
  const page = useLoaderData();
  if (page.data.length === 0) return <div className="container mx-auto p-8 text-white">Please publish your first page from Strapi Admin</div>;
  const contentSections = page.data[0].attributes.contentSections;
  return contentSections.map((section, index) => sectionRenderer(section, index));
}
_s(RootRoute, "U8MXhKkSw5vyjUYcAy3Nfy0oYzw=", false, function () {
  return [useLoaderData];
});
_c = RootRoute;
var _c;
$RefreshReg$(_c, "RootRoute");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;