// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Email.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Email.tsx");
  import.meta.hot.lastModified = "1716452662850.4663";
}
// REMIX HMR END

import FormSubmit from "~/routes/api.join-form";
import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return <div>
        <h1>hey</h1>
      </div>;
  } else if (error instanceof Error) {
    return <div>
        <h2>hey</h2>
      </div>;
  } else {
    return <h1>Unknown Error</h1>;
  }
}
_s(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = ErrorBoundary;
export default function Email({
  data
}) {
  return <section className="py-6 bg-gray-900 text-gray-50">
      <div className="container mx-auto flex flex-col justify-center p-4 space-y-8 md:p-10 lg:space-y-0 lg:space-x-12 lg:justify-between lg:flex-row">
        <div className="flex flex-col space-y-4 text-center lg:text-left">
          <h1 className="text-5xl font-bold leading-none">{data.title}</h1>
          <p className="text-lg">{data.description}</p>
        </div>
        <FormSubmit placeholder={data.emailPlaceholder} text={data.submitButton.text} />
        </div>
    </section>;
}
_c2 = Email;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "Email");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;