// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/render-button-style.ts"
);
import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

export function renderButtonStyle(type: string) {
	switch (type) {
		case "primary":
			return "px-8 py-3 text-lg font-semibold rounded bg-violet-400 text-gray-900";
		case "secondary":
			return "px-8 py-3 text-lg font-semibold border rounded border-gray-100";
		default:
			return "px-8 py-3 text-lg font-semibold rounded bg-violet-400 text-gray-900";
	}
}