// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HighlightedText.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HighlightedText.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

export default function HighlightedText({
  text,
  tag,
  className,
  color
}) {
  const tempText = text.split(" ");
  let result = [];
  result.push(`<${tag} class="${className ? className : ""}">`);
  tempText.forEach((word, index) => {
    if (word.includes("[")) {
      const highlight = word.replace("[", "").replace("]", "");
      result.push(`<span key=${index} class="${color ? color : ""}">${highlight}</span> `);
    } else result.push(word + " ");
  });
  result.push(`</${tag}>`);
  return <div dangerouslySetInnerHTML={{
    __html: result.join("")
  }} />;
}
_c = HighlightedText;
var _c;
$RefreshReg$(_c, "HighlightedText");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;